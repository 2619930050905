<template>
  <Animation3 />

  <!-- SEO Tags -->
  <teleport to="head">
    <meta name="description" :content="Seo.Description" />
    <meta name="keywords" :content="Seo.Keywords" />
    <link rel="alternate" hreflang="x-default" :href="Seo.HreflangDefault" />
    <link rel="alternate" hreflang="en-US" :href="Seo.HreflangCountry" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="Seo.OpenGraph.Title" />
    <meta property="og:site_name" :content="Seo.OpenGraph.SiteName" />
    <meta property="og:url" :content="Seo.OpenGraph.Url" />
    <meta property="og:description" :content="Seo.OpenGraph.Description" />
    <meta property="og:type" :content="Seo.OpenGraph.Website" />
    <meta property="og:image" :content="Seo.OpenGraph.Image" />
    <!-- ./Open Graph Tags -->

    <!-- Twitter Card Tags -->
    <meta name="twitter:card" :content="Seo.TwitterCard.Card" />
    <meta name="twitter:site" :content="Seo.TwitterCard.Site" />
    <meta name="twitter:title" :content="Seo.TwitterCard.Title" />
    <meta name="twitter:description" :content="Seo.TwitterCard.Description" />
    <meta name="twitter:image" :content="Seo.TwitterCard.Image" />
    <!-- ./Twitter Card Tags -->
  </teleport>
  <!-- ./SEO Tags -->

  <main id="services" class="what dark">
    <div id="digitalTransformation" class="banner container">
      <div
        data-aos="fade-up"
        data-aos-delay="1000"
        data-aos-duration="1000"
        class="header-section"
      >
        <span class="header-intro-caption">{{ content.SuperScriptTitle }}</span>
        <h1>{{ content.Mainheading }}</h1>
      </div>
      <a-row>
        <a-col
          :sm="{ span: 24 }"
          :md="{ span: 24 }"
          :lg="{ span: 24 }"
          :xl="{ span: 12 }"
          class="py-4 headerRow"
        >
          <p data-aos="fade-up" data-aos-delay="1500" data-aos-duration="1500">
            {{ content.Description }}
          </p>
        </a-col>
        <a-col
          :sm="{ span: 24 }"
          :md="{ span: 24 }"
          :lg="{ span: 24 }"
          :xl="{ span: 12 }"
          class="py-4 BannerPoints"
        >
          <ul class="customlist" v-for="sin in content.Points" :key="sin">
            <li class="list__item">{{ sin }}</li>
          </ul>
          <div style="margin: auto; padding: 15px">
            <Button
              :name="content.Points_ButtonText"
              @click="
                siteLanguage == 'EN'
                  ? $router.push('contact')
                  : $router.push('kontakt')
              "
            />
          </div>
        </a-col>
      </a-row>
      <div id="what-we-do-btn">
        <DoubleDownArrow id="what-we-do-arrow" class="dark header-arrows" />
        <br />
        <a
          href="#services-box"
          v-smooth-scroll="{ duration: 1000 }"
          class="header-btn"
        >
          {{ content.ButtonText }}</a
        >
      </div>
    </div>

    <div class="padding-break-rl accent"></div>
    <section id="services-box" class="service-top">
      <div id="blockchain" class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span
              style="
                display: inline-flex;
                column-gap: 15px;
                align-items: center;
                margin-bottom: 32px;
              "
            >
              <Bitcoin />
              <h3 style="margin-bottom: auto">
                {{ content.Blockchain.MainTitle }}
              </h3>
            </span>
            <h3 class="subheading">{{ content.Blockchain.SubHeading }}</h3>
          </div>
          <p>
            {{ content.Blockchain.Description[0] }}
          </p>
          <p>
            {{ content.Blockchain.Description[1] }}
          </p>
          <p>
            {{ content.Blockchain.Description[2] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in content.Blockchain.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <!-- <div data-aos="fade-down-left" class="flex justify-center">
          <img class=" " src="@/assets/illustration.png" style="width:500px; height:400px"/>
        </div> -->
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 12%"
        >
          <BlockchainImg />
        </div>
      </div>
    </section>
    <section id="services-box" class="service-top">
      <div id="web" class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <WebIcon />
              <h3>{{ content.Web.MainTitle }}</h3>
            </span>
            <h3 class="subheading">{{ content.Web.SubHeading }}</h3>
          </div>
          <p>
            {{ content.Web.Description }}
          </p>
          <ul class="customlist" v-for="sin in content.Web.Points" :key="sin">
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div data-aos="fade-down-left" class="flex justify-center">
          <WebSvg />
        </div>
      </div>
    </section>

    <section class="service-top">
      <div class="grid container"></div>
    </section>

    <div class="padding-div accent"></div>
    <div id="appDev" class="padding-break-lr dark"></div>
    <section class="service-bottom" id="websection">
      <div class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <AppIcon />

              <h3>{{ content.App.MainTitle }}</h3>
            </span>
            <h3 class="subheading">{{ content.App.SubHeading }}</h3>
          </div>
          <p>
            {{ content.App.Description }}
          </p>
          <ul class="customlist" v-for="sin in content.App.Points" :key="sin">
            <li class="list__item">{{ sin }}</li>
          </ul>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

        <div data-aos="fade-down-left" class="flex justify-center">
          <AppSvg />
          <!-- <img class=" " src="@/assets/animation.png" /> -->
        </div>

        <div style="margin-top: 22%">
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <EnterpriseIcon />
              <h3>
                {{ content.EnterpriseApp.MainTitle }}
              </h3>
            </span>
          </div>
          <p>
            {{ content.EnterpriseApp.Description[0] }}
          </p>
          <p>
            {{ content.EnterpriseApp.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in content.EnterpriseApp.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <!-- <div data-aos="fade-down-left" class="flex justify-center">
          <img class=" " src="@/assets/illustration.png" style="width:500px; height:400px"/>
        </div> -->
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 22%"
        >
          <EADG />
        </div>
      </div>
    </section>

    <div class="padding-div dark"></div>
    <div class="padding-break-rl accent"></div>

    <section class="service-top">
      <div id="design" class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <Designuiux />
              <h3>{{ content.UIUX.MainTitle }}</h3>
            </span>
            <h3 class="subheading">
              {{ content.UIUX.SubHeading }}
            </h3>
          </div>
          <p>
            {{ content.UIUX.Description[0] }}

            <br />
            <br />
            {{ content.UIUX.Description[1] }}

            <br />
            <br />
            {{ content.UIUX.Description[2] }}
          </p>
          <ul class="customlist" v-for="sin in content.UIUX.Points" :key="sin">
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>

        <div data-aos="fade-down-left" class="flex justify-center">
          <UIUXSvg />
        </div>
        <div style="margin-top: 22%">
          <div data-aos="fade-down-left">
            <span style="display: inline-flex; column-gap: 15px">
              <GrowthMarketing />
              <h3>{{ content.GrowthMarketing.MainTitle }}</h3>
            </span>

            <h3 class="subheading">{{ content.GrowthMarketing.SubHeading }}</h3>
          </div>
          <p>
            {{ content.GrowthMarketing.Description[0] }}
            <br />
            <br />
            {{ content.GrowthMarketing.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in content.GrowthMarketing.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 22%"
        >
          <GrowthMarketingSvg />
        </div>
      </div>
    </section>

    <div class="padding-div accent"></div>
    <div class="padding-break-lr dark"></div>
    <section class="service-bottom last-element">
      <div id="digitalMarketing" class="grid container">
        <div>
          <div data-aos="fade-down-left" class="flex">
            <DigitalMarketing style="width: 90px; margin-right: 5px" />
            <h3>
              {{ content.DigitalMarketing.MainTitle }}
            </h3>
          </div>
          <p>
            {{ content.DigitalMarketing.Description[0] }}
            <br />
            <br />
            {{ content.DigitalMarketing.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in content.DigitalMarketing.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div data-aos="fade-down-left" class="flex justify-center">
          <DigitalMarketingSvg />
        </div>
        <div>
          <div data-aos="fade-down-right" style="margin-top: 22%">
            <span style="display: inline-flex; column-gap: 15px">
              <NextGen style="width: 20%" />
              <h3>{{ content.NextGen.MainTitle }}</h3>
            </span>
            <h3 class="subheading">{{ content.NextGen.SubHeading }}</h3>
          </div>
          <p>
            {{ content.NextGen.Description[0] }}
            <br />
            <br />
            {{ content.NextGen.Description[1] }}
          </p>

          <ul
            class="customlist"
            v-for="sin in content.NextGen.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="width: 100%; max-width: 100%; margin-top: 22%"
        >
          <NextGenSvg />
        </div>
      </div>
    </section>

    <div class="padding-div dark"></div>
    <div class="padding-break-rl accent"></div>

    <section class="service-top">
      <div class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <CyberIcon />
              <h3>
                {{ content.Cyber.MainTitle }}
              </h3>
            </span>
            <h3 class="subheading">
              {{ content.Cyber.SubHeading }}
            </h3>
          </div>
          <p>
            {{ content.Cyber.Description[0] }}
          </p>
          <p>
            {{ content.Cyber.Description[1] }}
          </p>
          <ul class="customlist" v-for="sin in content.Cyber.Points" :key="sin">
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <!-- <div data-aos="fade-down-left" class="flex justify-center">
          <img class=" " src="@/assets/illustration.png" style="width:500px; height:400px"/>
        </div> -->
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 15%"
        >
          <Cyber />
        </div>
      </div>
    </section>

    <div class="padding-div accent"></div>

    <section class="quotes" :class="getCurrBg">
      <div class="container">
        <br />
        <br />
        <h2 class="testimonials-heading">
          {{ content.TestimonialsSectionTitle }}
        </h2>
        <div>
          <QuoteArrow class="quote-arrow left" @click="decrement" />
          <div class="content">
            <blockquote>
              {{ content.testimonial_data[testimonial_idx]["testimonial"] }}
            </blockquote>
          </div>
          <QuoteArrow class="quote-arrow right" @click="increment" />
        </div>
      </div>
    </section>
  </main>
</template>
<script>
//ASSETS

import EnterpriseIcon from "@/assets/icons/EnterpriseIcon.svg";
import Cyber from "@/assets/icons/cyber.svg";
import CyberIcon from "@/assets/icons/cyber-icon.svg";
import AppIcon from "@/assets/icons/AppIcon.svg";
import Designuiux from "@/assets/icons/Designuiux.svg";
import DigitalMarketing from "@/assets/icons/DigitalMarketing.svg";
import GrowthMarketing from "@/assets/icons/GrowthMarketing.svg";
import NextGen from "@/assets/icons/NextGen.svg";
import WebIcon from "@/assets/icons/WebIcon.svg";
import DoubleDownArrow from "@/assets/icons/double_down_arrow.svg";
import EADG from "@/assets/icons/First-illustration.svg";
import QuoteArrow from "@/assets/icons/quote_arrow.svg";
import WebSvg from "@/assets/icons/web_development.svg";
import BlockchainImg from "@/assets/icons/on_chain.svg";
import Bitcoin from "@/assets/icons/bitcoin.svg";
import AppSvg from "@/assets/icons/mob_watch-01.svg";
import UIUXSvg from "@/assets/icons/ui_ux.svg";
import NextGenSvg from "@/assets/icons/next_gen.svg";
import GrowthMarketingSvg from "@/assets/icons/growth_marketing.svg";
import DigitalMarketingSvg from "@/assets/icons/digital_marketing.svg";

//COMPONENTS

import Button from "@/components/Button";

import Animation3 from "@/components/Animation3";
import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";

const Scroll = require("react-scroll");
const scroll = Scroll.animateScroll;
const scroller = Scroll.scroller;

export default {
  computed: {
    getCurrBg: function() {
      return this.content.testimonial_data[this.testimonial_idx]["bg"];
    },
    reduceWidth: function() {
      return this.content.testimonial_data[this.testimonial_idx]["reduceWidth"];
    },
  },
  methods: {
    //  goto(refName) {
    //     var element = this.$refs[refName];
    //     var top = element.offsetTop;
    //    element.scrollIntoView({behavior: 'smooth'});
    //     window.scrollTo(0, top);
    //   },

    increment: function() {
      if (this.testimonial_idx <= this.content.testimonial_data.length - 2) {
        this.testimonial_idx = this.testimonial_idx + 1;
      } else {
        this.testimonial_idx = 0;
      }
    },
    decrement: function() {
      if (this.testimonial_idx >= 1) {
        this.testimonial_idx = this.testimonial_idx - 1;
      } else {
        this.testimonial_idx = this.content.testimonial_data.length - 1;
      }
    },
  },
  data: function() {
    return {
      content: null,
      testimonial_idx: 0,
      Seo: {},
      siteLanguage: "",
    };
  },
  props: ["section"],
  watch: {
    section: function(newVal) {
      scroller.scrollTo(newVal, { duration: 1500, delay: 100, smooth: true });
    },
  },
  components: {
    Button,
    DoubleDownArrow,
    EADG,
    QuoteArrow,
    WebSvg,
    UIUXSvg,
    AppSvg,
    NextGenSvg,
    GrowthMarketingSvg,
    DigitalMarketingSvg,
    Animation3,
    WebIcon,
    NextGen,
    GrowthMarketing,
    EnterpriseIcon,
    DigitalMarketing,
    Designuiux,
    AppIcon,
    BlockchainImg,
    Bitcoin,
    Cyber,
    CyberIcon,
  },
  created() {
    this.siteLanguage = localStorage.getItem("siteLanguage");
    this.siteLanguage == "GR"
      ? ((this.content = LanguageStrings.gr.ServicesPage),
        (this.Seo = SeoStrings.gr.Services))
      : ((this.content = LanguageStrings.en.ServicesPage),
        (this.Seo = SeoStrings.en.Services));
    document.querySelector("head title").innerText = this.content.MetaTitle;
  },

  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const square = entry.target.querySelector(".web");

        if (entry.isIntersecting) {
          square.classList.add("web");
          return; // if we added the class, exit the function
        }

        // We're not intersecting, so remove the class!
        // square.classList.remove('square-animation');
      });
    });

    observer.observe(document.querySelector("#websection"));

    if (this.section) {
      scroller.scrollTo(this.section, {
        duration: 1500,
        delay: 1500,
        smooth: true,
      });
    } else {
      scroll.scrollToTop();
    }
  },
};
</script>
